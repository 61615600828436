<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        v-html="formattedItem.title"
      />
      <v-list-item-subtitle
        v-html="formattedItem.subtitle"
      />
    </v-list-item-content>
    <b10-list-item-right-avatar
      v-if="formattedItem.badge"
    >
      {{ formattedItem.badge }}
    </b10-list-item-right-avatar>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { nonEmpty } from '@/utils/templates'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = this.$options.filters.linebreaksBr(nonEmpty`
        Parte Nº${item.idparte_trabajo} - ${item.orden_trabajo_serie_numero}`)
      item.subtitle = this.$options.filters.linebreaksBr(nonEmpty`
        ${item.cliente_nombre}`)
      item.avatar = item.cant_material
      item.avatarColor = 'info'
      if (item.con_devolucion_pendiente > 0) {
        item.badge = 'Devolución'
      }
      return item
    }
  }
}
</script>
